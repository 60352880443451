<template>
  <YmiField
    label="Логин"
    semibold
  >
    <YmiInput
      v-model="userLogin"
      size="lg"
      placeholder="Иванов Иван"
      data-test="input_login_username"
    />
  </YmiField>
  <YmiField
    label="Пароль"
    semibold
  >
    <YmiInput
      v-model="userPassword"
      type="password"
      size="md"
      data-test="input_login_password"
    />
  </YmiField>
  <ChangePassword
    v-if="newPasswordRequired"
    @score="
      score = $event.score;
      newPassword = $event.password;
    "
  />
  <YmiButton
    :label="!newPasswordRequired ? 'Войти' : 'Изменить и войти'"
    :disabled="scoreFailed"
    :loading="loadingBtn"
    data-test="btn_login"
    @click="login"
  />
</template>

<script setup>
import { useMagicKeys } from '@vueuse/core';
import { useAuthStore } from '~/stores/auth';
import { useModalStore } from '~/stores/modal';
import { useNotieStore } from '~/stores/notifications';

const { enter } = useMagicKeys();
const authStore = useAuthStore();
const modalStore = useModalStore();
const notieStore = useNotieStore();
const { $api } = useNuxtApp();

const userLogin = ref('');
const userPassword = ref('');
const loadingBtn = ref(false);
const newPasswordRequired = ref(false);
const newPassword = ref('');
const score = ref(0);
const scoreFailed = computed(() => (newPasswordRequired.value ? score.value < 55 : false));

const props = defineProps({
  embeded: {
    type: Boolean,
    default: false,
  },
});

watch(enter, async (v) => {
  if (v && userLogin.value && userPassword.value) {
    await login();
  }
});

const login = async () => {
  if (!userPassword.value || !userLogin.value) {
    return notieStore.add({
      type: 'error',
      message: 'Не указан логин или пароль',
    });
  }

  loadingBtn.value = true;
  try {
    if (newPasswordRequired.value) {
      await $api.web.UpdateUserInfo({
        user_old_pass: userPassword.value,
        user_new_pass: newPassword.value,
      });
    }

    const result = await authStore.login({
      [authStore.settings.login.fields.login]: userLogin.value,
      [authStore.settings.login.fields.password]: newPasswordRequired.value
        ? newPassword.value
        : userPassword.value,
      redirect: !props.embeded,
    });

    modalStore.login.visible = false;
    newPasswordRequired.value = Number(result.pass_update_required) === 1;
  } finally {
    loadingBtn.value = false;
  }
};

onMounted(() => {
  document.body.classList.add('overflow-hidden');
});

onBeforeUnmount(() => {
  document.body.classList.remove('overflow-hidden');
});
</script>
