<template>
  <div class="flex flex-col gap-2">
    <hr />
    <YmiField
      label="Новый пароль"
      semibold
    >
      <YmiInput
        v-model="newPassword"
        size="md"
        :error="scoreFailed"
      />
    </YmiField>
    <div class="flex select-none flex-col gap-2 text-justify text-xs">
      <div>
        Используйте комбинации латинских символов A-Z, цифр 0-9, чередования заглавных и строчных
        символов, длиной не менее 8 символов или
        <span
          class="cursor-pointer text-blue-400 underline decoration-dashed"
          @click="newPassword = generatePassword(8)"
        >
          сгенерируйте
        </span>
        пароль
      </div>
      <div>Не забудьте сохранить новый пароль</div>
    </div>
  </div>
</template>

<script setup>
const newPassword = ref('');

const emit = defineEmits(['score']);

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
};

const generatePassword = (passwordLength) => {
  const numberChars = '123456789';
  const upperChars = 'ABCDEFGHJKLMNPQRSTUVWXYZ';
  const lowerChars = 'abcdefghijkmnpqrstuvwxyz';
  const allChars = numberChars + upperChars + lowerChars;
  let randPasswordArray = Array(passwordLength);
  randPasswordArray[0] = numberChars;
  randPasswordArray[1] = upperChars;
  randPasswordArray[2] = lowerChars;
  randPasswordArray = randPasswordArray.fill(allChars, 3);
  return shuffleArray(
    randPasswordArray.map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    }),
  ).join('');
};

const scorePassword = (password) => {
  let score = 0;
  if (!password) return score;

  // award every unique letter until 5 repetitions
  const letters = {};
  for (let i = 0; i < password.length; i++) {
    letters[password[i]] = (letters[password[i]] || 0) + 1;
    score += 5.0 / letters[password[i]];
  }

  // bonus points for mixing it up
  const variations = {
    digits: /\d/.test(password),
    lower: /[a-z]/.test(password),
    upper: /[A-Z]/.test(password),
    nonWords: /\W/.test(password),
  };

  let variationCount = 0;
  for (const check in variations) {
    variationCount += variations[check] === true ? 1 : 0;
  }
  score += (variationCount - 1) * 10;

  return parseInt(score);
};

const scoreFailed = computed(() =>
  newPassword.value ? scorePassword(newPassword.value) < 50 : false,
);

watch(
  () => newPassword.value,
  (nv) => emit('score', { password: newPassword.value, score: scorePassword(nv) }),
);
</script>
